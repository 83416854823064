h1 {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 300;
  font-size: 40px;
  color: #080808;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

h1 em {
  font-style: normal;
  font-weight: 600;
}
.two-word-heading h1 {
  text-align: center;
  font-size: 50px;
  text-transform: uppercase;
  color: #222;
  letter-spacing: 1px;
  font-family: "Playfair Display", serif;
  font-weight: 400;
}
.two-word-heading h1 span {
  margin-top: 5px;
  font-size: 15px;
  color: #444;
  word-spacing: 1px;
  font-weight: normal;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-family: "Raleway", sans-serif;
  font-weight: 500;

  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  grid-template-rows: 27px 0;
  grid-gap: 20px;
  align-items: center;
}

.two-word-heading h1 span:after,
.two-word-heading h1 span:before {
  content: " ";
  display: block;
  border-bottom: 1px solid #ccc;
  border-top: 1px solid #ccc;
  height: 5px;
  background-color: #f8f8f8;
}

.single-word-heading h1 {
  font-size: 28px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.5em;
  padding-bottom: 15px;
  position: relative;
  margin-left: 50px;
}
.single-word-heading h1:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 5px;
  width: 55px;
  background-color: #111;
}
.single-word-heading h1:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 2px;
  height: 1px;
  width: 95%;
  max-width: 255px;
  background-color: #333;
}
.loading-icon-class {
  height: 400px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hide-scrollbar-similar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar-similar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
